/* You can add global styles to this file, and also import other style files */
@use '@tecalisdev/infinity';
@use '@tecalisdev/infinity/variables' as variables;
@import '~quill/dist/quill.snow.css';

:root #onivia {
	--color-primary-purple-01: #223268;
	--color-primary-purple-02: #f6f7ff;
	--color-primary-purple-03: #00a5aa;
	--color-primary-carnation-01: #ff5a3e;
	--color-primary-cherry-pie-01: #0e183e;
	--color-primary-cherry-pie-01-button: #233e99;
	--color-primary-cherry-pie-02: #e6ebf4;
	--color-primary-java-03: #00a5aa;

	--color-cherry-pie-01-opacity: rgba(14, 24, 62, 0.25);
}

html {
	body {
		&#onivia {
			main {
				&.auth {
					//GRAFISM LOGIN
					background-image: url('assets/images/background-onivia.png');
					background-repeat: no-repeat;
					background-position: 0 195%;
					background-size: contain;
					height: auto;
					min-height: 100vh;
					@media (max-width: 1200px) {
						background-position: 0 125%;
					}
					@media (max-width: 992px) {
						background-position: 0 120%;
					}
					@media (max-width: 768px) {
						background-position: 0 115%;
					}
					@media (max-width: 578px) {
						background-position: 0 110%;
					}

					@media (max-width: 768px) {
						.container {
							gap: 2.5rem;
						}
					}
					.auth {
						&__body {
							background-color: white;
							padding: 0.5rem;
							border-radius: 0.5rem;
							position: relative;
							z-index: 10;
						}
						&__link {
							position: relative;
							z-index: 10;
						}
					}
				}
			}
			//SPINNER
			.spinner {
				&__infinity {
					@keyframes rotate {
						0% {
							transform: rotate(0deg);
						}

						100% {
							transform: rotate(360deg);
						}
					}

					animation: rotate 2s linear infinite;
					position: relative;
					top: inherit;
					left: inherit;
				}
			}

			//DASHBOARD
			.template-dashboard {
				background-color: transparent;
			}
		}
	}
}

.lowest {
	box-shadow: inset variables.$size-3xs 0px 0 0px var(--color-primary-cherry-pie-03);
}

.low {
	box-shadow: inset variables.$size-3xs 0px 0 0px var(--color-primary-cherry-pie-03);
}

.medium {
	box-shadow: inset variables.$size-3xs 0px 0 0px var(--color-accent-yellow-01);
}

.high {
	box-shadow: inset variables.$size-3xs 0px 0 0px var(--color-accent-orange-01);
}

.highest {
	box-shadow: inset variables.$size-3xs 0px 0 0px var(--color-accent-orange-01);
}

// TODO: Borrar cuando se corrija en libreria
.collapse--expanded .collapse__body {
	max-height: initial !important;
}

.conversation__footer {
	pointer-events: none;
	opacity: 0.5;
}

.chip-group {
	flex-wrap: wrap;
}
